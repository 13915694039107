:root {
  --input-border-width: 0.125rem;
  --input-border-color: var(--color-black-30);
}

body {
  font-family: var(--font-family-default);
  background-color: #d2f0e9;
  color: #14555a;
  color: var(--color-emerald-green);
}

.main {
  margin: 2.875rem auto;
  max-width: 1100px;
}

section {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}

.content {
  width: 400px;
  text-align: center;
}

.content.content__aside {
  text-align: left;
}

.logo {
  width: 110px;
  height: auto;
  float: right;
}

.visually-hidden {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
}

/* DNB UI Lib theming */
.dnb-section.dnb-section--padding {
  padding: 2.5rem;
}

.dnb-section.dnb-section--no-box-shadow:after {
  box-shadow: none;
}

.dnb-p--medium-size {
  font-size: 1.25rem;
  font-size: var(--font-size-medium);
}

.dnb-p--xx-large-size {
  font-size: 3rem;
  font-size: var(--font-size-xx-large);
}

.oceanGreen {
  color: #00343e;
  color: var(--color-ocean-green);
}

.emeraldGreen {
  color: #14555a;
  color: var(--color-emerald-green);
}

.seaGreen {
  color: #007272;
  color: var(--color-sea-green);
}

.dnb-section:after {
  left: 0;
  width: 100%;
}

.dnb-label--green {
  color: #14555a;
  color: var(--color-emerald-green);
}

.btn-primary--green {
  background-color: #00343e;
  background-color: var(--color-ocean-green);
}

.dnb-input input.dnb-input__input.form-input {
  font-size: 1.625rem;
  color: #00343e;
  color: var(--color-ocean-green);
  font-family: var(--font-family-default);
}

.dnb-button__tooltip {
  box-shadow: inset 0 0 0 0.0625rem var(--color-black-30);
  color: #00343e;
  color: var(--color-ocean-green);
  width: 20px;
  height: 20px;
}

.dnb-button--hide-text.dnb-button--has-text,
.dnb-button--hide-text.dnb-button--has-text.dnb-button--size-medium {
  padding: 0;
}

.btn-primary--green.dnb-button--hide-text.dnb-button--has-text {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.dnb-button--hide-text .dnb-button__text {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
}

.dnb-button.dnb-button__tooltip {
  border: 0.0625rem solid var(--color-black-30);
  box-shadow: none;
}

.dnb-button__tooltip:not([disabled]) .dnb-button__icon {
  font-size: 10px;
  color: #00343e;
  color: var(--color-ocean-green);
}

.dnb-button__tooltip:active:not([disabled]),
html:not([data-is-touch]) .dnb-button__tooltip:active:not([disabled]) {
  background-color: #00343e;
  background-color: var(--color-ocean-green);
}

.dnb-button__tooltip:active:not([disabled]) .dnb-button__icon,
html:not([data-is-touch])
  .dnb-button__tooltip:active:not([disabled])
  .dnb-button__icon {
  color: #ffffff;
  color: var(--color-white);
}

.form-space {
  margin-bottom: 1rem;
  margin-bottom: var(--spacing-small);
}

.form-space:last-of-type {
  margin-bottom: 0;
}

@media (max-width: 21.875em) {
  .dnb-input input.dnb-input__input.form-input {
    font-size: 1.25rem;
    font-size: var(--font-size-medium);
  }
}

@media (max-width: 56.25em) {
  .main {
    margin: 1rem auto;
    margin: var(--spacing-small) auto;
  }

  .dnb-space__bottom--xx-large.dnb-space__bottom--x-small.title-space {
    margin-bottom: 1.5rem;
    margin-bottom: var(--spacing-medium);
  }

  .title {
    font-size: 1.625rem;
    font-size: var(--font-size-large);
  }

  .dnb-space__bottom--large.dnb-space__bottom--x-small.cta-search-loan {
    margin-top: 1.5rem;
    margin-top: var(--spacing-medium);
    margin-bottom: 1.5rem;
    margin-bottom: var(--spacing-medium);
  }

  .content {
    width: 100%;
    max-width: 25rem;
    margin: 0 0.5rem;
    margin: 0 var(--spacing-x-small);
  }

  .logo {
    float: none;
  }

  .dnb-section__calculator {
    display: flex;
    justify-content: center;
  }

  .dnb-section.dnb-section--padding {
    padding: 1rem;
  }

  .monthly-payment {
    font-size: 2.125rem;
    font-size: var(--font-size-x-large);
  }
}

.tooltip {
  position: relative;
  display: inline-block;
}

.body {
  font-size: 0.875rem;
  font-size: var(--font-size-x-small);
  width: 16.5rem;
  padding: 0.75rem 0.875rem 0.625rem 0.875rem;
  margin-top: 0.5rem;
  position: absolute;
  left: 50%;
  bottom: 2.1875rem;
  right: 0;
  transform: translateX(-50%);
  z-index: 10;
  border-radius: 0.25rem;
  box-shadow: 0px 2px 8px rgba(0, 52, 62, 0.1);
  color: #ffffff;
  color: var(--color-white);
  background-color: #00343e;
  background-color: var(--color-ocean-green);
}

.body:before {
  content: " ";
  width: 0;
  height: 0;
  border-radius: 0.125rem;
  border-left: 0.625rem solid transparent;
  border-right: 0.625rem solid transparent;
  border-top: 0.625rem solid var(--color-ocean-green);
  position: absolute;
  bottom: -0.525rem;
  left: 50%;
  transform: translateX(-50%);
}

.body p:not(:last-child) {
  margin-bottom: 1em;
}
